import "./ads.css";
import { Button, Paper, TextField } from "@mui/material";
import SimpleDragDrop from "../../components/SimpleDragDrop";
import React, { useState, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";
import { useLocalization } from "../../contexts/LocalizationContext";

export default function Ads() {
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(null);

  const fileToUrl = (file) => {
    return URL.createObjectURL(file);
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedPhoto(file);
    setSelectedPhotoUrl(fileToUrl(file));
  };

  const handleClear = () => {
    setSelectedPhoto(null);
  };
  const { locale } = useLocalization();

  return (
    <div className="addContainer">
      <Paper
        sx={{
          borderRadius: "12px",
          display: "grid",
          alignContent: "space-between",
          gridTemplateRows: " calc(100%-5rem) 5rem",
          background: "#f0f0f0",
        }}
      >
        <SimpleDragDrop
          sx={{ width: "100%" }}
          selectedPhoto={selectedPhoto}
          setSelectedPhoto={setSelectedPhoto}
          handleFileChange={handleFileChange}
          setSelectedPhotoUrl={setSelectedPhotoUrl}
        />
        <Button
          sx={{
            bgcolor: "#2FDCC7",
            height: "5rem",
            color: "white",
            fontSize: locale === "en" ? "24px" : "32px",
            borderRadius: "0 0 12px 12px",
            ":hover": {
              bgcolor: "#6C778B",
            },
          }}
          onClick={() => {
            setSelectedPhoto();
            setSelectedPhotoUrl();
          }}
        >
          {locale === "en" ? "Add Addvertisment" : "إضافة إعلان"}
        </Button>
      </Paper>
      <Paper
        sx={{
          borderRadius: "12px",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selectedPhoto ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              justifyItems: "center",
              height: "50rem",
            }}
          >
            <div
              style={{
                textAlign: locale === "en" ? "left" : "right",
                width: "60rem",
                display: "grid",
                justifyContent: "center",
                justifyItems: "center",
                alignItems: "space-between",
                padding: "0",
                gap: "1rem",
              }}
            >
              <ClearIcon
                onClick={handleClear}
                // variant="contained"
                sx={{
                  color: "#989898",
                  padding: "0",
                  ":hover": {
                    color: "#C8C8C8",
                  },
                }}
                alt="حذف"
              />
              <img
                src={selectedPhotoUrl}
                style={{ height: "20rem", width: "20rem" }}
              />
              <div>
                {locale === "en" ? "Name" : "الاسم"}

                <TextField
                  value={selectedPhoto.name}
                  sx={{
                    width: "20rem",
                    direction: locale === "en" ? "ltr" : "rtl",
                    display: "flex",
                  }}
                />
              </div>
              <div>
                {locale === "en" ? "Time in seconds" : "الوقت بالثواني"}
                <TextField
                  direction={locale === "en" ? "ltr" : "rtl"}
                  id="outlined-number"
                  defaultValue={0}
                  type="number"
                  helperText={
                    locale === "en" ? "From 0 to 400" : "من 0 إلى 400"
                  }
                  inputProps={{
                    min: 0,
                    max: 400,
                  }}
                  InputLabelProps={{
                    direction: locale === "en" ? "ltr" : "rtl",
                    shrink: false, // Hides the label
                  }}
                  style={{
                    width: "20rem",
                    display: "flex",
                    direction: locale === "en" ? "ltr" : "rtl",
                  }}
                  FormHelperTextProps={{
                    style: {
                      textAlign: locale === "en" ? "right" : "left", // Align the helper text
                    },
                  }}
                />
              </div>

              <FormGroup
                style={{
                  width: "20rem",
                  display: "flex",
                  flexDirection: "row",
                  direction: locale === "en" ? "ltr" : "rtl",
                }}
              >
                <FormControlLabel
                  control={
                    <Switch
                      defaultChecked
                      sx={{
                        width: 64, // Adjust the width
                        height: 38, // Adjust the height
                        "& .MuiSwitch-switchBase": {
                          "&.Mui-checked": {
                            transform: "translateX(26px)", // Adjust the translateX value for the checked state
                            color: "#2FDCC7",
                            "& + .MuiSwitch-track": {
                              backgroundColor: "#2FDCC7", // Adjust the background color of the track when checked
                            },
                          },
                        },
                        "& .MuiSwitch-thumb": {
                          width: 24, // Adjust the width of the thumb
                          height: 24, // Adjust the height of the thumb
                        },
                        "& .MuiSwitch-track": {
                          borderRadius: 20 / 2, // Adjust the border radius of the track
                          height: 20, // Adjust the height of the track
                        },
                      }}
                    />
                  }
                  label={locale === "en" ? "Activate" : "تفعيل"}
                  style={{
                    // justifyContent: locale === "en" ? "start" : "end",
                    width: "100%",
                    flexDirection: locale === "en" ? "row" : "row", // Align the switch and label correctly
                  }}
                />
              </FormGroup>
              <Button
                sx={{
                  bgcolor: "#667085",
                  height: "3rem",
                  width: "20rem",
                  color: "white",
                  fontSize: "20px",
                  // borderRadius: "0 0 12px 12px",
                  ":hover": {
                    bgcolor: "#6C778B",
                  },
                }}
                onClick={() => {
                  setSelectedPhoto();
                  setSelectedPhotoUrl();
                }}
              >
                {locale === "en" ? "Save" : "حفظ"}
              </Button>
            </div>
          </div>
        ) : (
          <div
            draggable
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              const droppedFile = e.dataTransfer.files[0];
              setSelectedPhoto(droppedFile);
            }}
            style={{
              border: "2px dotted gray",
              borderRadius: "5px",
              // padding: "16rem",
              textAlign: "center",
              // marginTop: "2px",
              width: "30rem",
              height: "30rem",
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <p style={{ color: "#989898" }}>
                {" "}
                {locale === "en"
                  ? "Drag and drop or"
                  : "قم بإدراج صورة جديدة أو "}
              </p>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                // dir={locale === "en" ? "rtl" : "ltr"}
                sx={{
                  width: locale === "en" ? "8rem" : "6rem",
                  bgcolor: "rgb(102, 112, 133)",
                  display: "flex",
                  justifyContent: "space-around",
                  ":hover": {
                    bgcolor: "#C8C8C8",
                  },
                }}
              >
                {locale === "en" ? "Upload" : "تحميل "}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Button>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
}
