import React, { forwardRef, useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { Paper, Autocomplete, TextField } from "@mui/material";
import { useLocalization } from "../../contexts/LocalizationContext";
import { tableIcons } from "../../tableIcons";
const subscriptionOptions = [
  { value: 10, label: { en: "10 devices", ar: "10 أجهزة" } },
  { value: 20, label: { en: "20 devices", ar: "20 جهازًا" } },
  { value: 30, label: { en: "30 devices", ar: "30 جهازًا" } },
  { value: 40, label: { en: "40 devices", ar: "40 جهازًا" } },
  { value: 50, label: { en: "50 devices", ar: "50 جهازًا" } },
];

const defaultMaterialTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:not(.export-button)": {
            color: "#333333",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold",
          fontSize: "20px",
          color: "#333333",
        },
      },
    },
  },
});

export default function Subscribtions() {
  const { locale } = useLocalization();
  const [data, setData] = useState([
    { id: 1, type: "Basic", devices: 10 },
    { id: 2, type: "Standard", devices: 20 },
    { id: 3, type: "Premium", devices: 30 },
  ]);

  const columns = [
    {
      field: "type",
      title: locale === "en" ? "Subscription Type" : "نوع الاشتراك",
      editComponent: (props) => (
        <Autocomplete
          options={subscriptionOptions}
          getOptionLabel={(option) => option.label[locale]}
          value={
            subscriptionOptions.find(
              (option) => option.value === props.value
            ) || null
          }
          onChange={(event, newValue) => props.onChange(newValue?.value || "")}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
    {
      field: "devices",
      title: locale === "en" ? "Number of Devices" : "عدد الأجهزة",
      type: "numeric",
    },
  ];

  return (
    <div className="dcontainer">
      <Paper
        elevation={3}
        sx={{
          // width: "calc(100% - 6%)",
          // gridRowStart: "2",
          borderRadius: "12px",
          width: "100%",
          height: "100%",
          // display: "flex",
          // justifyContent: "flex-start",
          // alignItems: "flex-start",
        }}
        style={{
          gridRow: "2/5",
        }}
      >
        {" "}
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            title=""
            columns={columns}
            data={data}
            icons={tableIcons}
            options={{
              search: false,
              paging: false,
              exportButton: true,
              headerStyle: {
                textAlign: locale === "en" ? "left" : "right",
              },
              cellStyle: {
                textAlign: locale === "en" ? "left" : "right",
              },
              detailPanelColumnAlignment: locale === "en" ? "right" : "right",
            }}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center", // Align items in the center vertically
                    width: "98%",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <IconButton
                      onClick={() => {}}
                      style={{
                        color: "#667085",
                        marginLeft: locale === "en" ? "0" : "10px",
                        marginRight: locale === "ar" ? "10px" : "0",
                      }}
                    >
                      <SaveAlt />
                    </IconButton>
                    <IconButton onClick={() => {}} style={{ color: "#667085" }}>
                      <AddBox />
                    </IconButton>
                  </div>
                  <h1 style={{ fontSize: "40px" }}>
                    {locale === "en" ? "Subscriptions" : "الاشتراكات"}
                  </h1>
                </div>
              ),
            }}
            editable={{
              onRowAdd: (newData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    setData([...data, newData]);
                    resolve();
                  }, 600);
                }),
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataUpdate = [...data];
                    const index = oldData.tableData.id;
                    dataUpdate[index] = newData;
                    setData([...dataUpdate]);
                    resolve();
                  }, 600);
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);
                    resolve();
                  }, 600);
                }),
            }}
            localization={{
              header: {
                actions: locale === "en" ? "Actions" : "الإجراءات",
              },
              body: {
                addTooltip: locale === "en" ? "Add" : "إضافة",
                deleteTooltip: locale === "en" ? "Delete" : "حذف",
                editTooltip: locale === "en" ? "Edit" : "تعديل",
                editRow: {
                  deleteText:
                    locale === "en"
                      ? "Are you sure you want to delete this row?"
                      : "هل أنت متأكد أنك تريد حذف هذا الصف؟",
                  cancelTooltip: locale === "en" ? "Cancel" : "إلغاء",
                  saveTooltip: locale === "en" ? "Save" : "حفظ",
                },
              },
            }}
          />
        </ThemeProvider>
      </Paper>
    </div>
  );
}
