import React from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  ReferenceLine,
} from "recharts";
import { useLocalization } from "../contexts/LocalizationContext";

const Totals = ({ data }) => {
  const { locale } = useLocalization();

  // Localization mapping for labels
  const labels = {
    en: {
      donations: "Donations",
      donors: "Donors",
    },
    ar: {
      donations: "التبرعات",
      donors: "المتبرعين",
    },
  };

  // Calculate the maximum value for the ReferenceLine
  const maxDonations = Math.max(
    ...data.flatMap((entry) => [
      entry["التبرعات"] || 0,
      entry["المتبرعين"] || 0,
    ])
  );

  return (
    <ResponsiveContainer width="90%" height="80%">
      <LineChart width={500} height={300} data={data}>
        <CartesianGrid strokeDasharray="3 0" vertical={false} />
        <YAxis axisLine={false} />
        <Tooltip />
        <Legend iconType="circle" />
        <Line
          type="monotone"
          dataKey={"التبرعات"}
          stroke="#D5CABD"
          strokeWidth={3}
          activeDot={{ r: 8 }}
        />
        <Line
          type="monotone"
          dataKey={"المتبرعين"}
          stroke="#FF8066"
          strokeWidth={3}
          activeDot={{ r: 8 }}
        />
        <ReferenceLine
          x={maxDonations}
          stroke="#D5CABD"
          strokeDasharray="3 3"
        />
        {/* Add localized chart title */}
      </LineChart>
    </ResponsiveContainer>
  );
};

export default Totals;
