import React from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
} from "recharts";
import { useLocalization } from "../contexts/LocalizationContext";

const Example = ({ dataAr, dataEn }) => {
  const { locale } = useLocalization();

  return (
    <div
      style={{
        width: "100%",
        height: "80%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        margin: "0",
        overflow: "scroll",
      }}
    >
      <ResponsiveContainer width="95%">
        <BarChart
          data={locale === "en" ? dataEn : dataAr}
          // margin={{
          //   top: 20,
          //   right: 30,
          //   left: 20,
          //   bottom: 5,
          // }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip
            cursor={{ fill: "transparent" }} // Makes the cursor invisible
            contentStyle={{ backgroundColor: "rgba(255, 255, 255, 0.8)" }} // Lightens the background color of the tooltip
          />
          <Legend iconType="circle" wrapperStyle={{ textColor: "#00000" }} />
          <Bar
            yAxisId="left"
            dataKey={locale === "en" ? "Donations" : "التبرعات"}
            fill="#D5CABD"
          />
          <Bar
            yAxisId="right"
            dataKey={locale === "en" ? "Donors" : "المتبرعين"}
            fill="#FF8066"
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Example;
