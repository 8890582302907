import React, { forwardRef, useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import AddBox from "@material-ui/icons/AddBox";
import SaveAlt from "@material-ui/icons/SaveAlt";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import ScreenLockPortraitIcon from "@mui/icons-material/ScreenLockPortrait";
import { useLocalization } from "../contexts/LocalizationContext";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import DevicesTable from "../components/DevicesTable";
import { tableIcons } from "../tableIcons";
const charityNames = [
  "جمعية البر",
  "مؤسسة خيرية",
  "دار الأيتام",
  "الهلال الأحمر",
  "بيت الزكاة",
  "الجمعية الخيرية",
  "جمعية الإحسان",
  "جمعية النور",
];

const subscriptionOptions = [
  { value: 10, label: { en: "10 devices", ar: "10 أجهزة" } },
  { value: 20, label: { en: "20 devices", ar: "20 جهازًا" } },
  { value: 30, label: { en: "30 devices", ar: "30 جهازًا" } },
  { value: 40, label: { en: "40 devices", ar: "40 جهازًا" } },
  { value: 50, label: { en: "50 devices", ar: "50 جهازًا" } },
];
const subscriptionStatusOptions = [
  { value: "active", label: { en: "Activate", ar: "تفعيل" } },
  { value: "stopped", label: { en: "stop", ar: "إيقاف التفعيل" } },
  {
    value: "requested",
    label: { en: "Unpaid", ar: "غير مدفوع" },
  },
];
const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: "#2FDCC7", // Set your desired color here
}));

function CustomEditComponent({ data, activate }) {
  const { locale } = useLocalization();

  const defaultMaterialTheme = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            // Apply color to all MuiIconButtons except those with the "export-button" class
            "&:not(.export-button)": {
              color: "#333333",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: "bold",
            fontSize: "20px",
            color: "#333333", // Dark grey color
          },
        },
      },
    },
  });

  const navigate = useNavigate();
  const handleRowClick = (id, name) => {
    navigate(`/home/charities/${id}?name=${name}`);
  };

  const [columns, setColumns] = useState([
    {
      field: "subscriptionStatus",
      title: locale === "en" ? "Subscription Status" : "حالة الاشتراك",
      editComponent: (props) => (
        <Autocomplete
          options={subscriptionStatusOptions}
          getOptionLabel={(option) => option.label[locale]}
          value={
            subscriptionStatusOptions.find(
              (option) => option.value === props.value
            ) || null
          }
          onChange={(event, newValue) => props.onChange(newValue?.value || "")}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
    {
      field: "subscriptionType",
      title: locale === "en" ? "Subscription Type" : "نوع الاشتراك",
      editComponent: (props) => (
        <Autocomplete
          options={subscriptionOptions}
          getOptionLabel={(option) => option.label[locale]}
          value={
            subscriptionOptions.find(
              (option) => option.value === props.value
            ) || null
          }
          onChange={(event, newValue) => props.onChange(newValue?.value || "")}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
    {
      field: "adminUser",
      title: locale === "en" ? "Admin User" : "اسم المستخدم",
    },
    {
      field: "adminName",
      title: locale === "en" ? "Admin Name" : "اسم المسؤول",
    },
    {
      field: "charityEmail",
      title: locale === "en" ? "Charity Email" : "البريد الإلكتروني",
    },
    {
      field: "phoneNumber",
      title: locale === "en" ? "Phone Number" : "رقم الهاتف",
    },
    {
      field: "charityName",
      title: locale === "en" ? "Charity Name" : "اسم اللجنة",
      editComponent: (props) => (
        <Autocomplete
          options={charityNames}
          value={props.value || ""}
          onChange={(event, newValue) => props.onChange(newValue)}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
  ]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleDialogOpen = (rowData) => {
    setSelectedRow(rowData);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedRow(null);
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    console.log("Confirmed for:", selectedRow);
    handleDialogClose();
  };

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialTable
        title=""
        columns={columns}
        icons={tableIcons}
        data={data}
        options={{
          search: false,
          paging: false,
          exportButton: true,
          headerStyle: {
            textAlign: locale === "en" ? "left" : "right",
          },
          cellStyle: {
            textAlign: locale === "en" ? "left" : "right",
          },
          detailPanelColumnAlignment: locale === "en" ? "right" : "right",
        }}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", // Align items in the center vertically
                width: "98%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={() => {}}
                  style={{
                    color: "#667085",
                    marginLeft: locale === "en " ? "0" : "10px",
                    marginRight: locale === "ar " ? "" : "10px",
                  }}
                >
                  <SaveAlt />
                </IconButton>
                <IconButton onClick={() => {}} style={{ color: "#667085" }}>
                  <AddBox />
                </IconButton>
              </div>
              {/* <MTableToolbar {...props} /> */}
              <h1 style={{ fontSize: "40px" }}>
                {locale === "en" ? "Charities" : "اللجان"}
              </h1>
            </div>
          ),
        }}
        editable={{
          onRowAdd: (newData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                // setData([...data, newData]);

                resolve();
              }, 1000);
            }),
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                // setData([...dataUpdate]);
                resolve();
              }, 1000);
            }),
        }}
        localization={{
          header: {
            actions: locale === "en" ? "Actions" : "الإجراءات", // Custom label for the actions column
          },
          body: {
            editTooltip: locale === "en" ? "edit" : "تعديل",
            editRow: {
              deleteText:
                locale === "en"
                  ? "Are You Sure You Want To Delete?"
                  : "هل أنت متأكد أنك تريد حذف هذا الصف؟",
              cancelTooltip: locale === "en" ? "cancel" : "إلغاء",
              saveTooltip: locale === "en" ? "save" : "حفظ",
            },
          },
        }}
        actions={[
          // {
          //   icon: tableIcons.Zero,
          //   tooltip: "تصفير بيانات الحساب",
          //   onClick: (event, rowData) => handleDialogOpen(rowData),
          // },
          {
            icon:
              activate === "activated"
                ? ScreenLockPortraitIcon
                : tableIcons.Activate,
            tooltip:
              activate === "activated"
                ? locale === "en"
                  ? "Activate"
                  : "إيقاف تفعيل اللجنة"
                : locale === "en"
                ? "Deactivate"
                : "تفعيل اللجنة",
            onClick:
              activate === "activated"
                ? (event, rowData) => {}
                : (event, rowData) => {},
          },
        ]}
        onRowClick={(event, rowData) => {
          handleRowClick(rowData.ID, rowData.charityName);
        }}
        detailPanel={[
          {
            icon: DevicesOutlinedIcon,
            openIcon: DevicesOutlinedIcon,
            tooltip: "أجهزة اللجنة",
            render: (rowData) => {
              return (
                <div
                  style={{
                    fontSize: 100,
                    textAlign: "center",
                    color: "white",
                    backgroundColor: "#FDD835",
                  }}
                >
                  <DevicesTable />
                </div>
              );
            },
          },
        ]}
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <p>
            {locale === "en"
              ? "Are you sure you want to reset the device data and start fresh? All data will be saved in the device archive."
              : "هل أنت متأكد من أنك تريد تصفير بيانات الجهاز والبدء من جديد؟ جميع البيانات ستحفظ في أرشيف الجهاز"}
          </p>
        </DialogContent>
        <DialogActions
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
            sx={{
              bgcolor: "#667085",
              width: "35%",
              ":hover": { bgcolor: "#352D66" },
            }}
          >
            {locale === "en" ? "Cancel" : "إلغاء"}
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            sx={{
              bgcolor: "#2FDCC7",
              width: "35%",
              ":hover": { bgcolor: "#352D66" },
            }}
          >
            {locale === "en" ? "Confirm" : "تأكيد"}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default CustomEditComponent;
