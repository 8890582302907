import React from "react";
import "./devices.css";
import { Paper } from "@mui/material";
import SingleDeviceTable from "../../components/SingleDeviceTable";
import Title from "../../components/Title";

export default function SingleDevice() {
  const [value, setValue] = React.useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="dcontainer">
      <Paper
        elevation={3}
        sx={{
          gridRowStart: "2",
          // width: "calc(100% - 6%)",
          // margin: "3%",
          borderRadius: "12px",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <SingleDeviceTable
          sx={{
            borderRadius: "12px",
          }}
        />
      </Paper>
    </div>
  );
}
