import React, { useEffect, useState } from "react";
import Typography from "@mui/material/Typography";

function Counter({ target, duration = 5000, variant = "h4" }) {
  const [counter, setCounter] = useState(0);

  useEffect(() => {
    const increment = target / (duration / 50); // Calculate the increment value
    const intervalDuration = 20; // in milliseconds

    const interval = setInterval(() => {
      setCounter((prevCounter) => {
        if (prevCounter >= target) {
          clearInterval(interval);
          return target;
        }
        return prevCounter + increment;
      });
    }, intervalDuration);

    return () => clearInterval(interval);
  }, [target, duration]);

  return (
    <Typography variant={variant} style={{ fontWeight: "bold" }}>
      {Math.floor(counter)}
    </Typography>
  );
}

export default Counter;
