import React, { useEffect, useState } from "react";
import "./charity.css";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import Counter from "../../components/Counter";
import { useLocalization } from "../../contexts/LocalizationContext";
import AdminCharityHistoryTable from "../../components/AdminCharityHistoryTable";
import logo from "../../images/logoo.png";
import { Typography, Breadcrumbs, Link, Paper } from "@mui/material";
export default function Profile() {
  const { locale } = useLocalization();

  return (
    <div className="charityContainer">
      <div
        style={{
          margin: "0",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gridRow: "2/3",
          gridColumn: "2",
        }}
      >
        <div
          style={{
            borderRadius: "20px",
            height: "45%",
            width: "100%",
            backgroundColor: "#2FDCC7",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            padding: "1rem",
            color: "white",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {locale === "en" ? "Total Donors" : "إجمالي المتبرعين"}
          </Typography>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
              color: "white",
            }}
          >
            <GroupsOutlinedIcon style={{ fontSize: 50 }} />
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              <Counter target={100} />
            </Typography>
          </div>
        </div>
        <div
          style={{
            borderRadius: "20px",
            height: "45%",
            width: "100%",
            backgroundColor: "#667085",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            padding: "1rem",
            color: "white",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {locale === "en" ? "Total Donations" : "إجمالي التبرعات"}
          </Typography>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
              color: "white",
            }}
          >
            <PriceChangeOutlinedIcon style={{ fontSize: 50 }} />

            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              <Counter target={1045} />
            </Typography>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "0",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gridRow: "2/3",
          gridColumn: "1",
        }}
      >
        <div
          style={{
            borderRadius: "20px",
            height: "45%",
            width: "100%",
            backgroundColor: "#EBF5FF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            textAlign: "right",
            padding: "1rem",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#667085", fontWeight: "bold" }}
          >
            {locale === "en" ? "Active Devices" : "الأجهزة المفعلة"}
          </Typography>
          <Typography
            variant="h4"
            style={{ color: "#006ED3", fontWeight: "bold" }}
          >
            <Counter target={65} />
          </Typography>
        </div>
        <div
          style={{
            borderRadius: "20px",
            height: "45%",
            width: "100%",
            backgroundColor: "#EBF5FF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            textAlign: "right",
            padding: "1rem",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#667085", fontWeight: "bold" }}
          >
            {locale === "en" ? "Inactive Devices" : "الأجهزة غير المفعلة"}
          </Typography>
          <Typography
            variant="h4"
            style={{ color: "#006ED3", fontWeight: "bold" }}
          >
            <Counter target={73} />
          </Typography>
        </div>
      </div>
      <Paper
        elevation={3}
        style={{
          borderRadius: "12px",
          gridRow: "2/3",
          gridColumn: "3",
          display: "grid",
          // justifyContent: "space-around",
          alignItems: "center",
          justifyItems: locale === "en" ? "flex-end" : "flex-start",
          gridTemplateColumns: locale === "en" ? "20% 40% 40%" : "40% 40% 20%",
          padding: "4rem",
          direction: locale === "en" ? "ltr" : "rtl",
        }}
      >
        <div
          style={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: locale === "en" ? "flex-end" : "flex-start",

            alignItems: "flex-start",
          }}
        >
          <img
            src={logo}
            style={{
              height: "10rem",
              width: "auto",
            }}
          />
        </div>
        {/* <dev
          style={{
            display: "flex",
            flexDirection: "row-reverse",
            justifyContent: "space-around",
            alignItems: "center",
            width: "100%",
            padding: "2rem",
          }}
        > */}
        <dev
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: locale === "en" ? "flex-end" : "flex-start",
            margin: "0",
            padding: "0",
            height: "100%",
          }}
        >
          <Typography variant="h5" component="div" style={{ color: "#00000" }}>
            {locale === "en" ? "Charity Name" : "اسم اللجنة"}
          </Typography>
          <Typography
            sx={{ mb: 1.5 }}
            // color="text.secondary"
            style={{ color: "#667085" }}
          >
            لجنة بريمي
          </Typography>
          <Typography variant="h5" component="div" style={{ color: "#00000" }}>
            {locale === "en" ? "Phone Number" : "رقم الهاتف"}
          </Typography>
          <Typography
            sx={{ mb: 1.5 }}
            // color="text.secondary"
            style={{ color: "#667085" }}
          >
            99000000
          </Typography>
          <Typography variant="h5" component="div" style={{ color: "#00000" }}>
            {locale === "en" ? "Email" : "الإيميل"}
          </Typography>
          <Typography
            sx={{ mb: 1.5 }}
            // color="text.secondary"
            style={{ color: "#667085" }}
          >
            Adel@seebCharity.com
          </Typography>
        </dev>
        <dev
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-start",
            margin: "0",
            padding: "0",
            height: "100%",
          }}
        >
          <Typography variant="h5" component="div" style={{ color: "#00000" }}>
            {locale === "en" ? "Charity Admin" : "المسؤول في اللجنة"}
          </Typography>
          <Typography
            sx={{ mb: 1.5 }}
            // color="text.secondary"
            style={{ color: "#667085" }}
          >
            خلفان الخلفاني
          </Typography>
          <Typography variant="h5" component="div" style={{ color: "#00000" }}>
            {locale === "en" ? "Subscription Type" : "نوع الاشتراك"}
          </Typography>
          <Typography
            sx={{ mb: 1.5 }}
            // color="text.secondary"
            style={{ color: "#667085" }}
          >
            ٢٠/١٢ جهاز
          </Typography>
        </dev>
        {/* <dev
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-end",
              margin: "0",
              padding: "0",
              width: "90%",
            }}
          > */}

        {/* </dev> */}
      </Paper>
      <Paper
        elevation={3}
        style={{
          gridColumn: "1 / 4",
          gridRowStart: "4",
          borderRadius: "0 12px 12px 12px",
          backgroundColor: "#F5F5F5",
          height: "100%", // Set a fixed height to enable scrolling
          overflow: "auto", // Make the Paper scrollable
          display: "flex", // Ensure Paper is a flex container
          flexDirection: "column",
        }}
      >
        <AdminCharityHistoryTable />
      </Paper>
    </div>
  );
}
