import React, { useEffect, useState } from "react";
import "./dashboard.css";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import GroupsOutlinedIcon from "@mui/icons-material/GroupsOutlined";
import PriceChangeOutlinedIcon from "@mui/icons-material/PriceChangeOutlined";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import Totals from "../../components/Totals";
import Example from "../../components/Chart";
import Counter from "../../components/Counter";
import { styled } from "@mui/material/styles";
import { useLocalization } from "../../contexts/LocalizationContext";

const CustomTabs = styled(Tabs)(({ theme }) => ({
  "& .MuiTabs-indicator": {
    backgroundColor: "#352D66", // Customize the color as needed
  },
}));
const data1 = [
  {
    name: "Page A",
    التبرعات: 4000,
    المتبرعين: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    التبرعات: 3000,
    المتبرعين: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    التبرعات: 2000,
    المتبرعين: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    التبرعات: 2780,
    المتبرعين: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    التبرعات: 1890,
    المتبرعين: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    التبرعات: 2390,
    المتبرعين: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    التبرعات: 3490,
    المتبرعين: 4300,
    amt: 2100,
  },
];
const data2 = [
  {
    name: "Page 2",
    التبرعات: 2000,
    المتبرعين: 240,
    amt: 2400,
  },
  {
    name: "Page B",
    التبرعات: 3000,
    المتبرعين: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    التبرعات: 2000,
    المتبرعين: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    التبرعات: 2780,
    المتبرعين: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    التبرعات: 1890,
    المتبرعين: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    التبرعات: 2390,
    المتبرعين: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    التبرعات: 3490,
    المتبرعين: 4300,
    amt: 2100,
  },
];
const data3 = [
  {
    name: "Page 3",
    التبرعات: 4000,
    المتبرعين: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    التبرعات: 3000,
    المتبرعين: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    التبرعات: 2000,
    المتبرعين: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    التبرعات: 2780,
    المتبرعين: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    التبرعات: 1890,
    المتبرعين: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    التبرعات: 2390,
    المتبرعين: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    التبرعات: 3490,
    المتبرعين: 4300,
    amt: 2100,
  },
];
const dataEn = [
  {
    name: "Page A",
    Donors: 100,
    Donations: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    Donors: 3000,
    Donations: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    Donors: 2000,
    Donations: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    Donors: 2780,
    Donations: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    Donors: 1890,
    Donations: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    Donors: 2390,
    Donations: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    Donors: 3490,
    Donations: 4300,
    amt: 2100,
  },
];

const dataAr = [
  {
    name: "Page A",
    المتبرعين: 4000,
    التبرعات: 2400,
    amt: 2400,
  },
  {
    name: "Page B",
    المتبرعين: 3000,
    التبرعات: 1398,
    amt: 2210,
  },
  {
    name: "Page C",
    المتبرعين: 2000,
    التبرعات: 9800,
    amt: 2290,
  },
  {
    name: "Page D",
    المتبرعين: 2780,
    التبرعات: 3908,
    amt: 2000,
  },
  {
    name: "Page E",
    المتبرعين: 1890,
    التبرعات: 4800,
    amt: 2181,
  },
  {
    name: "Page F",
    المتبرعين: 2390,
    التبرعات: 3800,
    amt: 2500,
  },
  {
    name: "Page G",
    المتبرعين: 3490,
    التبرعات: 4300,
    amt: 2100,
  },
];
export default function Dashboard() {
  const [value, setValue] = React.useState(0);
  const [totals, setTotals] = useState(data1);
  const { locale } = useLocalization();

  const handleTotalsChange = (event, newValue) => {
    setTotals(newValue);
  };
  const handleCharitiesTotalsChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className="container">
      <CustomTabs
        value={totals}
        onChange={handleTotalsChange}
        centered
        sx={{
          display: "flex",
          height: "100%",
          width: "36%",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          bgcolor: "#F7F7F7",
          // height: "1rem",
          gridRowEnd: "2",
          borderRadius: "12px 12px 0 0", // Rounded top corners
        }}
      >
        <Tab
          label={locale === "en" ? "Days" : "يومي"}
          value={data1}
          style={{
            width: "33.33%",
            padding: "0.5rem 0",
            minWidth: "unset",
          }}
          sx={{
            "&.Mui-selected": {
              color: "#352D66", // Text color when selected
            },
          }}
        />
        <Tab
          label={locale === "en" ? "Months" : "شهري"}
          value={data2}
          style={{
            width: "33.33%",
            padding: "0.5rem 0",
            minWidth: "unset",
          }}
          sx={{
            "&.Mui-selected": {
              color: "#352D66", // Text color when selected
            },
          }}
        />
        <Tab
          label={locale === "en" ? "Years" : "سنوي"}
          value={data3}
          style={{
            width: "33.33%",
            padding: "0.5rem 0",
            minWidth: "unset",
          }}
          sx={{
            "&.Mui-selected": {
              color: "#352D66", // Text color when selected
            },
          }}
        />
      </CustomTabs>
      <Paper
        elevation={3}
        style={{
          borderRadius: "0 12px 12px 12px",
          gridRow: "2/3",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <dev
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "flex-end",
            margin: "0",
            padding: "0",
            width: "90%",
          }}
        >
          <Typography style={{ fontWeight: "bold" }}>
            {locale === "en"
              ? "Total Donations and Donors"
              : "إجمالي التبرعات والمتبرعين"}
          </Typography>
        </dev>
        <Totals data={totals} />
      </Paper>
      <div
        style={{
          margin: "0",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gridRow: "2/3",
        }}
      >
        <div
          style={{
            borderRadius: "20px",
            height: "45%",
            width: "100%",
            backgroundColor: "#2FDCC7",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            padding: "1rem",
            color: "white",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {locale === "en" ? "Total Donors" : "إجمالي المتبرعين"}
          </Typography>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
              color: "white",
            }}
          >
            <GroupsOutlinedIcon style={{ fontSize: 50 }} />
            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              <Counter target={115} />
            </Typography>
          </div>
        </div>
        <div
          style={{
            borderRadius: "20px",
            height: "45%",
            width: "100%",
            backgroundColor: "#667085",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            padding: "1rem",
            color: "white",
          }}
        >
          <Typography variant="h6" style={{ fontWeight: "bold" }}>
            {locale === "en" ? "Total Donations" : "إجمالي التبرعات"}
          </Typography>
          <div
            style={{
              display: "flex",
              width: "100%",
              justifyContent: "space-between",
              alignItems: "flex-end",
              color: "white",
            }}
          >
            <PriceChangeOutlinedIcon style={{ fontSize: 50 }} />

            <Typography variant="h4" style={{ fontWeight: "bold" }}>
              <Counter target={1286} />
            </Typography>
          </div>
        </div>
      </div>
      <div
        style={{
          margin: "0",
          padding: "0",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          gridRow: "2/3",
        }}
      >
        <div
          style={{
            borderRadius: "20px",
            height: "45%",
            width: "100%",
            backgroundColor: "#EBF5FF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            textAlign: "right",
            padding: "1rem",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#667085", fontWeight: "bold" }}
          >
            {locale === "en" ? "Active Devices" : "الأجهزة المفعلة"}
          </Typography>
          <Typography
            variant="h4"
            style={{ color: "#006ED3", fontWeight: "bold" }}
          >
            <Counter target={10} />
          </Typography>
        </div>
        <div
          style={{
            borderRadius: "20px",
            height: "45%",
            width: "100%",
            backgroundColor: "#EBF5FF",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            alignItems: "flex-end",
            textAlign: "right",
            padding: "1rem",
          }}
        >
          <Typography
            variant="h6"
            style={{ color: "#667085", fontWeight: "bold" }}
          >
            {locale === "en" ? "Inactive Devices" : "الأجهزة غير المفعلة"}
          </Typography>
          <Typography
            variant="h4"
            style={{ color: "#006ED3", fontWeight: "bold" }}
          >
            <Counter target={3} />
          </Typography>
        </div>
      </div>
      <CustomTabs
        value={value}
        onChange={handleCharitiesTotalsChange}
        centered
        sx={{
          display: "flex",
          height: "100%",
          width: "36%",
          justifyContent: "flex-start",
          alignItems: "flex-end",
          bgcolor: "#F7F7F7",
          height: "1rem",
          gridRowEnd: "4",
          borderRadius: "12px 12px 0 0", // Rounded top corners
        }}
      >
        <Tab
          label={locale === "en" ? "Years" : "سنوي"}
          style={{
            width: "33.33%",
            padding: "0.5rem 0",
            minWidth: "unset",
          }}
          sx={{
            "&.Mui-selected": {
              color: "#352D66", // Text color when selected
            },
          }}
        />
        <Tab
          label={locale === "en" ? "Months" : "شهري"}
          style={{
            width: "33.33%",
            padding: "0.5rem 0",
            minWidth: "unset",
          }}
          sx={{
            "&.Mui-selected": {
              color: "#352D66", // Text color when selected
            },
          }}
        />
        <Tab
          label={locale === "en" ? "Days" : "يومي"}
          style={{
            width: "33.33%",
            padding: "0.5rem 0",
            minWidth: "unset",
          }}
          sx={{
            "&.Mui-selected": {
              color: "#352D66", // Text color when selected
            },
          }}
        />
      </CustomTabs>
      <Paper
        elevation={3}
        style={{
          gridColumn: "1 / 4",
          borderRadius: "0 12px 12px 12px",
          bgcolor: "#F5F5F5",
        }}
      >
        <dev
          style={{
            display: "flex",
            // justifyContent: "space-between",
            alignItems: "flex-end",
            flexDirection: "column",
            margin: "0",
            padding: "0",
            width: "97.5%",
          }}
        >
          <FilterAltIcon />
          <Typography
            style={{ fontWeight: "bold", marginTop: "1rem", marginBottom: "0" }}
          >
            {locale === "en"
              ? "Total Donations and Donors"
              : "إجمالي التبرعات والمتبرعين"}
          </Typography>
        </dev>
        <Example dataEn={dataEn} dataAr={dataAr} />
      </Paper>
    </div>
  );
}
