import React from "react";
import "./login.css";
import login from "../../images/loginClipart.png";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";

const MyTextField = styled(TextField)({
  width: "20rem",
  borderRadius: "12",
});

export default function Login() {
  const navigate = useNavigate();

  return (
    <div className="loginContainer">
      <div className="loginClipart">
        <img
          src={login}
          alt="Login Clipart"
          //   style={{ width: "65%", height: "auto" }}
        />
      </div>
      <div className="loginForm">
        <Typography
          variant="h3"
          style={{ fontFamily: "Poppins", margin: "0", padding: "0" }}
        >
          مرحبا من جديد
        </Typography>
        <Typography
          variant="subtitle1"
          style={{ fontFamily: "Poppins", marginBottom: "3rem", padding: "0" }}
        >
          رجاء أدخل بياناتك
        </Typography>

        <div className="formFields">
          <Typography
            variant="subtitle1"
            style={{ fontFamily: "Poppins", margin: 0, padding: 0 }}
          >
            الإيميل
          </Typography>
          <MyTextField
            id="outlined-basic1"
            // label="Field 1"
            variant="outlined"
            style={{ width: "20rem", borderRadius: "12px" }}
          />
          <Typography
            variant="subtitle1"
            style={{ fontFamily: "Poppins", padding: 0 }}
          >
            الرمز السري
          </Typography>
          <MyTextField
            id="outlined-basic2"
            // label="Field 2"
            type="password"
            variant="outlined"
            // className="textField"
            style={{ width: "20rem", borderRadius: "12" }}
          />
          <Button
            variant="contained"
            style={{
              width: "20rem",
              backgroundColor: "#352D66",
              height: "3.5rem",
              marginTop: "30px",
              borderRadius: "12",
            }}
            onClick={() => navigate("/home")}
          >
            تسجيل الدخول
          </Button>
          <Typography
            variant="subtitle1"
            style={{
              fontFamily: "Poppins",
              margin: 0,
              padding: 0,
              color: "#352D66",
            }}
          >
            نسيت كلمة المرور؟
          </Typography>
        </div>
      </div>
    </div>
  );
}
