import React, { useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CheckBox from "@mui/icons-material/CheckBox";
import CheckBoxOutlineBlankOutlined from "@mui/icons-material/CheckBoxOutlineBlankOutlined";
import { useLocalization } from "../../contexts/LocalizationContext";
import { Paper } from "@mui/material";
import SaveAlt from "@material-ui/icons/SaveAlt";
import AddBox from "@material-ui/icons/AddBox";
import { tableIcons } from "../../tableIcons";
import CheckIcon from "@mui/icons-material/Check";
import HorizontalRuleIcon from "@mui/icons-material/HorizontalRule";

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: "#2FDCC7",
}));

const defaultMaterialTheme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: "#333333",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold",
          fontSize: "20px",
          color: "#333333",
        },
      },
    },
  },
});

export default function Users() {
  const { locale } = useLocalization();

  const [data, setData] = useState([
    {
      id: 1,
      userName: "User1",
      userPassword: "password1",
      viewDashboard: true,
      viewThankYou: false,
      editThankYou: false,
      viewAnnouncements: true,
      viewCharityInitiatives: false,
      viewDevices: true,
      editAnnouncements: false,
      editCharityInitiatives: true,
      editDevices: false,
    },
    {
      id: 2,
      userName: "User2",
      userPassword: "password2",
      viewDashboard: true,
      viewThankYou: false,
      editThankYou: false,
      viewAnnouncements: false,
      viewCharityInitiatives: true,
      viewDevices: false,
      editAnnouncements: true,
      editCharityInitiatives: false,
      editDevices: true,
    },
    {
      id: 3,
      userName: "User3",
      userPassword: "password3",
      viewDashboard: true,
      viewThankYou: false,
      editThankYou: false,
      viewAnnouncements: true,
      viewCharityInitiatives: true,
      viewDevices: false,
      editAnnouncements: true,
      editCharityInitiatives: false,
      editDevices: true,
    },
  ]);

  const columns = [
    {
      field: "editUsers",
      title: locale === "en" ? "Edit Users" : "تعديل المستخدمين  ",
      render: (rowData) =>
        rowData.editUsers ? (
          <CheckIcon color="#2FDCC7" sx={{ color: "#2FDCC7" }} />
        ) : (
          <HorizontalRuleIcon />
        ),
      editComponent: (props) => (
        <CustomIconButton onClick={() => props.onChange(!props.value)}>
          {props.value ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </CustomIconButton>
      ),
    },
    {
      field: "editSubscriptions",
      title: locale === "en" ? "Edit Subscriptions" : "تعديل الباقات ",
      render: (rowData) =>
        rowData.editSubscriptions ? (
          <CheckIcon color="#2FDCC7" sx={{ color: "#2FDCC7" }} />
        ) : (
          <HorizontalRuleIcon />
        ),
      editComponent: (props) => (
        <CustomIconButton onClick={() => props.onChange(!props.value)}>
          {props.value ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </CustomIconButton>
      ),
    },
    {
      field: "editCharities",
      title: locale === "en" ? "Edit Charities" : " تعديل اللجان ",
      render: (rowData) =>
        rowData.editCharities ? (
          <CheckIcon color="#2FDCC7" sx={{ color: "#2FDCC7" }} />
        ) : (
          <HorizontalRuleIcon />
        ),
      editComponent: (props) => (
        <CustomIconButton onClick={() => props.onChange(!props.value)}>
          {props.value ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </CustomIconButton>
      ),
    },
    {
      field: "editDevices",
      title: locale === "en" ? "Edit Devices" : "تعديل الأجهزة",
      render: (rowData) =>
        rowData.editDevices ? (
          <CheckIcon color="#2FDCC7" sx={{ color: "#2FDCC7" }} />
        ) : (
          <HorizontalRuleIcon />
        ),
      editComponent: (props) => (
        <CustomIconButton onClick={() => props.onChange(!props.value)}>
          {props.value ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </CustomIconButton>
      ),
    },
    {
      field: "viewUsers",
      title: locale === "en" ? "View Users" : "عرض صفحة المستخدمين",
      render: (rowData) =>
        rowData.viewUsers ? (
          <CheckIcon color="#2FDCC7" sx={{ color: "#2FDCC7" }} />
        ) : (
          <HorizontalRuleIcon />
        ),
      editComponent: (props) => (
        <CustomIconButton onClick={() => props.onChange(!props.value)}>
          {props.value ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </CustomIconButton>
      ),
    },
    {
      field: "viewSubscriptions",
      title: locale === "en" ? "View Subscriptions" : "عرض صفحة الباقات",
      render: (rowData) =>
        rowData.viewSubscriptions ? (
          <CheckIcon color="#2FDCC7" sx={{ color: "#2FDCC7" }} />
        ) : (
          <HorizontalRuleIcon />
        ),
      editComponent: (props) => (
        <CustomIconButton onClick={() => props.onChange(!props.value)}>
          {props.value ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </CustomIconButton>
      ),
    },
    {
      field: "viewDevices",
      title: locale === "en" ? "View Devices" : "عرض صفحة الأجهزة",
      render: (rowData) =>
        rowData.viewDevices ? (
          <CheckIcon color="#2FDCC7" sx={{ color: "#2FDCC7" }} />
        ) : (
          <HorizontalRuleIcon />
        ),
      editComponent: (props) => (
        <CustomIconButton onClick={() => props.onChange(!props.value)}>
          {props.value ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </CustomIconButton>
      ),
    },
    {
      field: "viewCharities",
      title: locale === "en" ? "View Charities" : "عرض صفحة اللجان",
      render: (rowData) =>
        rowData.viewCharities ? (
          <CheckIcon color="#2FDCC7" sx={{ color: "#2FDCC7" }} />
        ) : (
          <HorizontalRuleIcon />
        ),
      editComponent: (props) => (
        <CustomIconButton onClick={() => props.onChange(!props.value)}>
          {props.value ? <CheckBox /> : <CheckBoxOutlineBlankOutlined />}
        </CustomIconButton>
      ),
    },
    {
      title: locale === "en" ? "Password" : "كلمة المرور",
      field: "userPassword",
      render: (rowData) => (
        <span>{"•".repeat(rowData.userPassword.length)}</span>
      ),
      editComponent: (props) => (
        <input
          type="text"
          value={props.value}
          onChange={(e) => props.onChange(e.target.value)}
        />
      ),
    },
    {
      title: locale === "en" ? "User Name" : "اسم المستخدم",
      field: "userName",
    },
  ];

  const handleDelete = (id) => {
    const updatedData = data.filter((row) => row.id !== id);
    setData(updatedData);
  };

  const handleAddUser = () => {
    const newUser = {
      id: data.length + 1,
      userName: "",
      userPassword: "",
      viewAnnouncements: false,
      viewCharityInitiatives: false,
      viewDevices: false,
      editAnnouncements: false,
      editCharityInitiatives: false,
      editDevices: false,
    };
    setData([...data, newUser]);
  };

  return (
    <div className="dcontainer">
      <Paper
        sx={{
          width: "100%",
          height: "100%",
        }}
        style={{ gridRow: "2/5" }}
      >
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            title={locale === "en" ? "User Permissions" : "صلاحيات المستخدمين"}
            columns={columns}
            icons={tableIcons}
            data={data}
            options={{
              search: false,
              paging: false,
              exportButton: true,
              headerStyle: {
                textAlign: locale === "en" ? "left" : "right",
                padding: "0",
                fontSize: locale === "en" ? "1rem" : "1.2rem",
              },
              cellStyle: {
                textAlign: "center",
              },
              detailPanelColumnAlignment: locale === "en" ? "right" : "right",
            }}
            localization={{
              header: {
                actions: locale === "en" ? "Actions" : "الإجراءات",
              },
              body: {
                editTooltip: locale === "en" ? "Edit" : "تعديل",
                deleteTooltip: locale === "en" ? "Delete" : "حذف",
                editRow: {
                  deleteText:
                    locale === "en"
                      ? "Are you sure you want to delete this row?"
                      : "هل أنت متأكد أنك تريد حذف هذا الصف؟",
                  cancelTooltip: locale === "en" ? "Cancel" : "إلغاء",
                  saveTooltip: locale === "en" ? "Delete" : "حذف",
                },
              },
              toolbar: {
                exportTitle: locale === "en" ? "Export" : "تصدير",
              },
            }}
            editable={{
              onRowUpdate: (newData, oldData) =>
                new Promise((resolve, reject) => {
                  const dataUpdate = [...data];
                  const index = oldData.tableData.id;
                  dataUpdate[index] = newData;
                  setData([...dataUpdate]);
                  resolve();
                }),
              onRowDelete: (oldData) =>
                new Promise((resolve, reject) => {
                  setTimeout(() => {
                    const dataDelete = [...data];
                    const index = oldData.tableData.id;
                    dataDelete.splice(index, 1);
                    setData([...dataDelete]);
                    resolve();
                  }, 600);
                }),
            }}
            components={{
              Toolbar: (props) => (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "center",
                    width: "98%",
                  }}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <IconButton style={{ color: "#667085" }}>
                      <SaveAlt />
                    </IconButton>
                    <IconButton
                      onClick={handleAddUser}
                      style={{ color: "#667085" }}
                    >
                      <AddBox />
                    </IconButton>
                  </div>
                  <h1 style={{ fontSize: "40px" }}>
                    {locale === "en" ? "Users" : "المستخدمين"}
                  </h1>
                </div>
              ),
            }}
          />
        </ThemeProvider>
      </Paper>
    </div>
  );
}
