import { Button, Paper, TextField } from "@mui/material";
import React, { useState, useEffect } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ClearIcon from "@mui/icons-material/Clear";
import { useLocalization } from "../../contexts/LocalizationContext";

import "./video.css";
export default function Video() {
  const [selectedPhoto, setSelectedPhoto] = useState();
  const [selectedPhotoUrl, setSelectedPhotoUrl] = useState(null);
  const handleFileChange = (event) => {
    const file = event.target.files[0];
    setSelectedPhoto(file);
    setSelectedPhotoUrl(fileToUrl(file));
  };
  const fileToUrl = (file) => {
    return URL.createObjectURL(file);
  };
  const handleClear = () => {
    setSelectedPhoto(null);
  };
  const { locale } = useLocalization();

  return (
    <div className="vidContainer">
      {" "}
      <Paper
        sx={{
          borderRadius: "12px",
          display: "grid",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {selectedPhoto ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              justifyItems: "center",
              height: "50rem",
            }}
          >
            <div
              style={{
                textAlign: "right",
                width: "60rem",
                display: "grid",
                justifyContent: "center",
                justifyItems: "center",
                alignItems: "space-between",
                padding: "0",
                gap: "1rem",
              }}
            >
              <ClearIcon
                onClick={handleClear}
                // variant="contained"
                sx={{
                  color: "#989898",
                  padding: "0",
                  ":hover": {
                    color: "#C8C8C8",
                  },
                }}
                alt="حذف"
              />
              <img
                src={selectedPhotoUrl}
                style={{ height: "20rem", width: "20rem" }}
              />
              <div style={{ textAlign: locale === "en" ? "left" : "right" }}>
                {locale === "en" ? "NAME" : "الاسم"}
                <TextField
                  value={selectedPhoto.name}
                  sx={{
                    width: "20rem",
                    direction: locale === "en" ? "ltr" : "rtl",
                    display: "flex",
                  }}
                />
              </div>
              {/* <div>
                الوقت بالثواني
                <TextField
                  direction="rtl"
                  id="outlined-number"
                  defaultValue={0}
                  type="number"
                  helperText="من 0 إلى 400 "
                  inputProps={{
                    min: 0,
                    max: 400,
                  }}
                  InputLabelProps={{
                    direction: "rtl",
                    shrink: false, // Hides the label
                  }}
                  style={{ width: "20rem", display: "flex", direction: "rtl" }}
                />
              </div> */}

              {/* <ThemeProvider theme={theme}>
          <CssBaseline />
          <TextField
            helperText="الوقت"
            sx={{
              width: "20rem",
              direction: "rtl",
              "& .MuiInputLabel-root": {
                direction: "rtl", // Ensuring the label is also RTL
              },
              "& .MuiInputBase-input": {
                direction: "rtl", // Ensuring the input text is RTL
              },
            }}
            InputLabelProps={{
              sx: {
                direction: "rtl", // Applying RTL to the label
              },
            }}
          />
        </ThemeProvider> */}

              <Button
                sx={{
                  bgcolor: "#667085",
                  height: "3rem",
                  width: "20rem",
                  color: "white",
                  fontSize: "20px",
                  // borderRadius: "0 0 12px 12px",
                  ":hover": {
                    bgcolor: "#6C778B",
                  },
                }}
                onClick={() => {
                  setSelectedPhoto();
                  setSelectedPhotoUrl();
                }}
              >
                {locale === "en" ? "Save" : "حفظ"}
              </Button>
            </div>
          </div>
        ) : (
          <div
            draggable
            onDragOver={(e) => e.preventDefault()}
            onDrop={(e) => {
              e.preventDefault();
              const droppedFile = e.dataTransfer.files[0];
              setSelectedPhoto(droppedFile);
            }}
            style={{
              border: "2px dotted gray",
              borderRadius: "5px",
              // padding: "16rem",
              textAlign: "center",
              // marginTop: "2px",
              width: "30rem",
              height: "30rem",
              display: "grid",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div>
              <p style={{ color: "#989898" }}>
                {" "}
                {locale === "en"
                  ? "Drag and drop a video or"
                  : "قم بإدراج فيديو جديد أو "}
              </p>
              <input
                type="file"
                accept="image/*"
                style={{ display: "none" }}
                onChange={handleFileChange}
              />
              <Button
                component="label"
                variant="contained"
                startIcon={<CloudUploadIcon />}
                dir={locale === "en" ? "rtl" : "ltr"}
                sx={{
                  width: locale === "en" ? "12rem" : "8rem",
                  bgcolor: "rgb(102, 112, 133)",
                  display: "flex",
                  justifyContent: "space-around",
                  ":hover": {
                    bgcolor: "#C8C8C8",
                  },
                }}
              >
                {locale === "en" ? "Upload Video" : "تحميل فيديو"}
                <input
                  type="file"
                  accept="image/*"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />
              </Button>
            </div>
          </div>
        )}
      </Paper>
    </div>
  );
}
