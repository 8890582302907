import React, { forwardRef, useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Counter from "./Counter";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { MTableToolbar } from "material-table";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import ScreenLockPortraitIcon from "@mui/icons-material/ScreenLockPortrait";
import { useLocalization } from "../contexts/LocalizationContext";
import { tableIcons } from "../tableIcons";

const charityNames = [
  "جمعية البر",
  "مؤسسة خيرية",
  "دار الأيتام",
  "الهلال الأحمر",
  "بيت الزكاة",
  "الجمعية الخيرية",
  "جمعية الإحسان",
  "جمعية النور",
];

const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: "#2FDCC7", // Set your desired color here
}));

function CustomEditComponent({ data, activate }) {
  const { locale } = useLocalization();

  const defaultMaterialTheme = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            // Apply color to all MuiIconButtons except those with the "export-button" class
            "&:not(.export-button)": {
              color: "#333333",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: "bold",
            fontSize: "20px",
            color: "#333333", // Dark grey color
          },
        },
      },
    },
  });

  const navigate = useNavigate();
  const handleRowClick = (id, name) => {
    navigate(`/home/adminDevices/${id}?name=${name}`);
  };

  const [columns, setColumns] = useState([
    {
      field: "NumberOfDonors",
      title: locale === "en" ? "Donors" : "المتبرعين",
      render: (rowData) => (
        <Counter
          target={rowData.NumberOfDonors}
          duration={5000}
          variant={"subtitle"}
        />
      ),
      editable: "never",
    },
    {
      field: "TotalDonations",
      title: locale === "en" ? "Donations" : "إجمالي التبرعات",
      render: (rowData) => (
        <Counter
          target={rowData.TotalDonations}
          duration={5000}
          variant={"subtitle"}
        />
      ),
      editable: "never",
    },
    {
      field: "LastSeen",
      title: locale === "en" ? "Last Seen" : "آخر ظهور",
      editable: "never",
    },
    {
      field: "ComplianceStatus",
      title: locale === "en" ? "Status" : "حالة الاتصال",
      render: (rowData) => {
        return rowData.ComplianceStatus === "متصل" ? (
          <Chip
            label={locale === "en" ? "Online" : rowData.ComplianceStatus}
            variant="outlined"
            sx={{
              width: "4.5rem",
              color: "#009900", // Green color for "متصل"
              borderColor: "#009900",
              fontWeight: "bold",
            }}
          />
        ) : (
          <Chip
            label={locale === "en" ? "Offline" : rowData.ComplianceStatus}
            variant="outlined"
            sx={{
              width: "4.5rem",
              color: "#FF0000", // Red color for other values
              borderColor: "#FF0000",
              fontWeight: "bold",
            }}
          />
        );
      },
      editable: "never",
    },
    {
      field: "Location",
      title: locale === "en" ? "Location" : "الموقع",
    },
    {
      field: "charityName",
      title: locale === "en" ? "Charity Name" : "اسم اللجنة",
      editComponent: (props) => (
        <Autocomplete
          options={charityNames}
          value={props.value || ""}
          onChange={(event, newValue) => props.onChange(newValue)}
          renderInput={(params) => <TextField {...params} variant="standard" />}
        />
      ),
    },
    {
      field: "DeviceName",
      title: locale === "en" ? "Device Name" : "اسم الجهاز",
      editable: "never",
    },
    {
      field: "ID",
      title: locale === "en" ? "ID" : "الرمز التعريفي",
      editable: "never",
    },
  ]);

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleDialogOpen = (rowData) => {
    setSelectedRow(rowData);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedRow(null);
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    console.log("Confirmed for:", selectedRow);
    handleDialogClose();
  };

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialTable
        title=""
        columns={columns}
        icons={tableIcons}
        data={data}
        options={{
          search: false,
          paging: false,
          exportButton: true,
          headerStyle: {
            textAlign: locale === "en" ? "left" : "right",
          },
          cellStyle: {
            textAlign: "center",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "98%",
              }}
            >
              <MTableToolbar {...props} />

              <h1 style={{ fontSize: "40px" }}>
                {locale === "en" ? "Devices" : "جميع الأجهزة"}
              </h1>
            </div>
          ),
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                const index = oldData.tableData.id;
                dataUpdate[index] = newData;
                // setData([...dataUpdate]);
                resolve();
              }, 1000);
            }),
        }}
        localization={{
          header: {
            actions: locale === "en" ? "Actions" : "الإجراءات", // Custom label for the actions column
          },
          body: {
            editTooltip: locale === "en" ? "edit" : "تعديل",
            editRow: {
              deleteText:
                locale === "en"
                  ? "Are You Sure You Want To Delete?"
                  : "هل أنت متأكد أنك تريد حذف هذا الصف؟",
              cancelTooltip: locale === "en" ? "cancel" : "إلغاء",
              saveTooltip: locale === "en" ? "save" : "حفظ",
            },
          },
        }}
        actions={[
          {
            icon: tableIcons.Zero,
            tooltip: "تصفير بيانات الحساب",
            onClick: (event, rowData) => handleDialogOpen(rowData),
          },
          {
            icon:
              activate === "activated"
                ? ScreenLockPortraitIcon
                : tableIcons.Activate,
            tooltip:
              activate === "activated"
                ? locale === "en"
                  ? "Activate"
                  : "إلغاء تفعيل الجهاز"
                : locale === "en"
                ? "Deactivate"
                : "تفعيل الجهاز",
            onClick:
              activate === "activated"
                ? (event, rowData) => {}
                : (event, rowData) => {},
          },
        ]}
        onRowClick={(event, rowData) => {
          handleRowClick(rowData.ID, rowData.DeviceName);
        }}
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent
          sx={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <p>
            {locale === "en"
              ? "Are you sure you want to reset the device data and start fresh? All data will be saved in the device archive."
              : "هل أنت متأكد من أنك تريد تصفير بيانات الجهاز والبدء من جديد؟ جميع البيانات ستحفظ في أرشيف الجهاز"}
          </p>
        </DialogContent>
        <DialogActions
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
            sx={{
              bgcolor: "#667085",
              width: "35%",
              ":hover": { bgcolor: "#352D66" },
            }}
          >
            {locale === "en" ? "Cancel" : "إلغاء"}
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            sx={{
              bgcolor: "#2FDCC7",
              width: "35%",
              ":hover": { bgcolor: "#352D66" },
            }}
          >
            {locale === "en" ? "Confirm" : "تأكيد"}
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default CustomEditComponent;
