import React, { useState, useEffect } from "react";
import "../devices/devices.css";
import { Paper } from "@mui/material";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import InitiativesTable from "../../components/InitiativesTable";
import { styled } from "@mui/material/styles";
import { useLocalization } from "../../contexts/LocalizationContext";

const data2 = [
  {
    Name: "كفالة يتيم",
    thawaniCode: "3994DR4994049",
  },
  {
    Name: "سقيا ماء",
    thawaniCode: "3994DR4994049",
  },
  {
    Name: "كهاتين",
    thawaniCode: "3994DR4994049",
  },
  {
    Name: "حقيبة مدرسية",
    thawaniCode: "3994DR4994049",
  },
  {
    Name: "كن ضياء",
    thawaniCode: "3994DR4994049",
  },
];
const data1 = [
  {
    Name: "زكاة الفطر",
    thawaniCode: "3994DR4994049",
  },
  {
    Name: "كسوة عيد",
    thawaniCode: "3994DR4994049",
  },
];
export default function Initiatives() {
  const [activateStatus, setActivateStatus] = useState("activated");
  const CustomTabs = styled(Tabs)(({ theme }) => ({
    gridRowEnd: "1",
    "& .MuiTabs-indicator": {
      backgroundColor: "#352D66", // Customize the color as needed
    },
  }));
  const { locale } = useLocalization();
  const [totals, setTotals] = useState(data1);
  const handleTotalsChange = (event, newValue) => {
    setTotals(newValue);
  };
  useEffect(() => {
    if (totals === data1) {
      setActivateStatus("deActivated");
    } else {
      setActivateStatus("activated");
    }
  }, [totals]);
  return (
    <div className="dcontainer">
      <CustomTabs
        value={totals}
        onChange={handleTotalsChange}
        centered
        sx={{
          display: "flex",
          height: "100%",
          width: locale === "en" ? "13%" : "10%",
          justifyContent: "flex-end",
          alignItems: "flex-end",
          bgcolor: "#F7F7F7",
          gridRowEnd: "2",
          borderRadius: "12px 12px 0 0",
        }}
      >
        <Tab
          value={data2}
          label={locale === "en" ? "Active" : "مفعلة"}
          style={{
            width: "50%",
            padding: "1rem 0",
            minWidth: "unset",
            height: "2rem",
            fontWeight: "900",
          }}
          sx={{
            "&.Mui-selected": {
              color: "#352D66", // Text color when selected
            },
          }}
        />
        <Tab
          value={data1}
          label={locale === "en" ? "Inactive" : "غير مفعلة"}
          style={{
            width: "50%",
            padding: "1rem 0",
            minWidth: "unset",
            height: "2rem",
            fontWeight: "900",
          }}
          sx={{
            "&.Mui-selected": {
              color: "#352D66", // Text color when selected
            },
          }}
        />
      </CustomTabs>
      <Paper
        elevation={3}
        sx={{
          // width: "calc(100% - 6%)",
          // gridRowStart: "2",
          borderRadius: "12px",
          width: "100%",
          height: "100%",
          // display: "flex",
          // justifyContent: "flex-start",
          // alignItems: "flex-start",
        }}
      >
        {" "}
        <InitiativesTable
          sx={{
            borderRadius: "12px",
            width: "100%",
            height: "100%",
            // display: "flex",
            // justifyContent: "stretch",
            // alignItems: "flex-start",
          }}
          data={totals}
          activate={activateStatus}
        />
      </Paper>
    </div>
  );
}
