import React, { useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import {
  Typography,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  Autocomplete,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import { SaveAlt } from "@mui/icons-material";
import { useLocation } from "react-router-dom";
import { MTableToolbar } from "material-table";
import { useLocalization } from "../contexts/LocalizationContext";
import { tableIcons } from "../tableIcons";

export default function AdminCharityHistoryTable() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const charityName = query.get("name") || "Charity";
  const { locale } = useLocalization();
  const [dialogOpen, setDialogOpen] = useState(false);
  // const [addDialogOpen, setAddDialogOpen] = useState(false);
  const handleAddDialogOpen = () => {
    setDialogOpen(true);
  };
  const [value, setValue] = useState("");
  const handleChange = (newValue) => {
    setValue(newValue);
  };
  const [columns, setColumns] = useState([
    {
      field: "paymentDate",
      title: locale === "en" ? "Payment Date" : "تاريخ الدفع",
    },
    {
      field: "amount",
      title: locale === "en" ? "Amount" : "المبلغ",
      render: (rowData) => (
        <Typography variant="subtitle">
          {rowData.amount.toLocaleString(locale === "en" ? "en-US" : "ar-EG")}
        </Typography>
      ),
    },
    {
      field: "numberOfDevices",
      title: locale === "en" ? "Number of Devices" : "عدد الأجهزة",
    },
    {
      field: "subscriptionType",
      title: locale === "en" ? "Subscription Type" : "نوع الاشتراك",
    },
    {
      field: "startDate",
      title: locale === "en" ? "Start Date" : "تاريخ البداية",
    },
    {
      field: "endDate",
      title: locale === "en" ? "End Date" : "تاريخ الانتهاء",
    },
    {
      field: "status",
      title: locale === "en" ? "Status" : "الحالة",
    },
  ]);

  const data = [
    {
      paymentDate: "2024-07-21",
      amount: 100,
      subscriptionType: locale === "en" ? "Basic" : "أساسي",
      startDate: "2024-07-01",
      endDate: "2025-07-01",
      numberOfDevices: "10",
      status: locale === "en" ? "Active" : "نشط",
    },
    {
      paymentDate: "2023-07-21",
      amount: 200,
      subscriptionType: locale === "en" ? "Premium" : "مميز",
      startDate: "2023-07-01",
      endDate: "2024-07-01",
      numberOfDevices: "10",
      status: locale === "en" ? "Expired" : "منتهي",
    },
    {
      paymentDate: "2023-07-21",
      amount: 200,
      subscriptionType: locale === "en" ? "Premium" : "مميز",
      startDate: "2023-07-01",
      endDate: "2024-07-01",
      numberOfDevices: "10",
      status: locale === "en" ? "Expired" : "منتهي",
    },
    {
      paymentDate: "2024-07-21",
      amount: 100,
      subscriptionType: locale === "en" ? "Basic" : "أساسي",
      startDate: "2024-07-01",
      endDate: "2025-07-01",
      numberOfDevices: "10",
      status: locale === "en" ? "Pending" : "في انتظار التفعيل",
    },
  ];

  const [selectedRow, setSelectedRow] = useState(null);

  const handleDialogOpen = (rowData) => {
    setSelectedRow(rowData);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedRow(null);
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    console.log("Confirmed for:", selectedRow);
    handleDialogClose();
  };

  const defaultMaterialTheme = createTheme({
    height: "100%",
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:not(.export-button)": {
              color: "#333333",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: "bold",
            fontSize: "20px",
            color: "#333333",
          },
        },
      },
    },
  });
  const subscriptionOptions = [
    { value: 10, label: { en: "10 devices", ar: "10 أجهزة" } },
    { value: 20, label: { en: "20 devices", ar: "20 جهازًا" } },
    { value: 30, label: { en: "30 devices", ar: "30 جهازًا" } },
    { value: 40, label: { en: "40 devices", ar: "40 جهازًا" } },
    { value: 50, label: { en: "50 devices", ar: "50 جهازًا" } },
  ];
  return (
    <ThemeProvider theme={defaultMaterialTheme} sx={{ height: "100%" }}>
      <MaterialTable
        title=""
        columns={columns}
        icons={tableIcons}
        data={data}
        options={{
          search: false,
          paging: false,
          exportButton: true,
          headerStyle: {
            textAlign: locale === "en" ? "left" : "right",
          },
          cellStyle: {
            textAlign: "center",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "98%",
              }}
            >
              {/* <MTableToolbar {...props} /> */}
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {/* <IconButton
                  onClick={() => {}}
                  style={{
                    color: "#667085",
                    height: "2.5rem",
                    marginLeft: locale === "en " ? "0" : "10px",
                    marginRight: locale === "ar " ? "" : "10px",
                  }}
                >
                  <SaveAlt />
                </IconButton> */}
                <Button
                  size="big"
                  onClick={handleAddDialogOpen}
                  // variant="outlined"
                  sx={{
                    color: "#667085",
                    fontSize: locale === "en" ? "1.2rem" : "1.5rem",
                    height: "2.5rem",
                    padding: 0,
                    marginLeft: locale === "en" ? "10px" : "0",
                    marginRight: locale === "en" ? "0" : "10px",
                  }}
                >
                  {locale === "en" ? "UPGRADE" : "ترقية الاشتراك"}
                </Button>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginTop: "2rem",
                  alignItems: locale === "en" ? "flex-end" : "flex-start",
                  direction: locale === "en" ? "ltr" : "rtl",
                }}
              >
                <Typography variant="h3">
                  {locale === "en"
                    ? "Payment History"
                    : "أرشيف الدفع والاشتراكات"}
                </Typography>
              </div>
            </div>
          ),
        }}
        editable={{
          onRowAdd: {},
        }}
        localization={{
          header: {
            actions: locale === "en" ? "Actions" : "الإجراءات",
          },
          body: {
            editTooltip: locale === "en" ? "Edit" : "تعديل",
            editRow: {
              deleteText:
                locale === "en"
                  ? "Are you sure you want to delete this row?"
                  : "هل أنت متأكد أنك تريد حذف هذا الصف؟",
              cancelTooltip: locale === "en" ? "Cancel" : "إلغاء",
              saveTooltip: locale === "en" ? "Save" : "حفظ",
            },
          },
        }}
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <p>
            {locale === "en"
              ? "You can upgrade your package now by choosing any higher grade package and you will pay only the difference."
              : "يمكنك أن تختار ترقية باقتك باختيار أي باقة أعلى منها وستدفع الفرق فقط"}
          </p>
          <Autocomplete
            label="عدد الأجهزة"
            options={subscriptionOptions}
            getOptionLabel={(option) => option.label[locale] || ""}
            value={
              subscriptionOptions.find((option) => option.value === value) ||
              null
            }
            onChange={(event, newValue) => handleChange(newValue?.value || "")}
            renderInput={(params) => (
              <TextField
                style={{ width: "25rem" }}
                {...params}
                variant="standard"
                helperText={
                  locale === "en"
                    ? "You can only upgrade"
                    : "يمكنك أن تختار باقة أعلى من باقتك فقط "
                }
              />
            )}
          />
        </DialogContent>
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
            sx={{
              backgroundColor: "#667085",
              width: "35%",
              "&:hover": {
                backgroundColor: "#352D66",
              },
            }}
          >
            إلغاء
          </Button>

          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            sx={{
              backgroundColor: "#2FDCC7",
              width: "35%",
              ":hover": { bgcolor: "#352D66" },
            }}
          >
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}
