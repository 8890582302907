import React, { forwardRef, useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import { AddBox, SaveAlt } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Button,
  TextField,
  DialogTitle,
} from "@mui/material";
import { useLocalization } from "../contexts/LocalizationContext";
import { useNavigate } from "react-router-dom";
import ScreenLockPortraitIcon from "@mui/icons-material/ScreenLockPortrait";
import { tableIcons } from "../tableIcons";

const theme = createTheme({
  components: {
    MuiIconButton: {
      styleOverrides: {
        root: {
          "&:not(.export-button)": {
            color: "#333333",
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: "bold",
          fontSize: "20px",
          color: "#333333",
          textAlign: "right", // Align table headers to the right
        },
        body: {
          textAlign: "right",
        },
      },
    },
  },
});

// CustomEditComponent
const CustomEditComponent = ({ data, activate }) => {
  const { locale } = useLocalization();
  const navigate = useNavigate();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [newRowData, setNewRowData] = useState({ thawaniCode: "", Name: "" });

  const columns = [
    {
      field: "thawaniCode",
      title: locale === "en" ? "Thawani Code" : "رمز ثواني",
    },
    { field: "Name", title: locale === "en" ? "Name" : "الاسم" },
  ];

  const handleRowClick = (id, name) =>
    navigate(`/home/devices/${id}?name=${name}`);

  const handleDialogOpen = (rowData) => {
    setSelectedRow(rowData);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedRow(null);
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    console.log("Confirmed for:", selectedRow);
    handleDialogClose();
  };

  const handleAddDialogOpen = () => {
    setAddDialogOpen(true);
  };

  const handleAddDialogClose = () => {
    setAddDialogOpen(false);
  };

  const handleAddRow = () => {
    // Add new row to data
    data.push(newRowData);
    setNewRowData({ thawaniCode: "", Name: "" });
    handleAddDialogClose();
  };

  return (
    <ThemeProvider theme={theme}>
      <MaterialTable
        title=""
        columns={columns}
        icons={tableIcons}
        data={data}
        options={{
          search: false,
          paging: false,
          exportButton: true,
          headerStyle: {
            textAlign: locale === "en" ? "left" : "right",
          },
          cellStyle: {
            textAlign: locale === "en" ? "left" : "right",
            paddingLeft: locale === "en" ? "3rem" : "0",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center", // Align items in the center vertically
                width: "98%",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <IconButton
                  onClick={handleAddDialogOpen}
                  style={{
                    color: "#667085",
                    marginLeft: locale === "en " ? "0" : "10px",
                    marginRight: locale === "ar " ? "" : "10px",
                  }}
                >
                  <SaveAlt />
                </IconButton>
                <IconButton
                  onClick={handleAddDialogOpen}
                  style={{ color: "#667085" }}
                >
                  <AddBox />
                </IconButton>
              </div>
              <h1 style={{ fontSize: "40px" }}>
                {locale === "en" ? "Initiatives" : "المبادرات"}
              </h1>
            </div>
          ),
        }}
        editable={{
          onRowUpdate: (newData, oldData) =>
            new Promise((resolve) => {
              setTimeout(() => {
                const dataUpdate = [...data];
                dataUpdate[oldData.tableData.id] = newData;
                resolve();
              }, 1000);
            }),
          onRowAdd: () =>
            new Promise((resolve, reject) => {
              setTimeout(() => {
                // No direct use since the Add button now handles adding
                resolve();
              }, 1000);
            }),
        }}
        localization={{
          header: { actions: locale === "en" ? "Actions" : "الإجراءات" },
          body: {
            editTooltip: locale === "en" ? "Edit" : "تعديل",
            editRow: {
              deleteText:
                locale === "en"
                  ? "Are you sure you want to delete this?"
                  : "هل أنت متأكد أنك تريد حذف هذه المبادرة؟",
              cancelTooltip: locale === "en" ? "Cancel" : "إلغاء",
              saveTooltip: locale === "en" ? "Save" : "حفظ",
            },
          },
        }}
        actions={[
          {
            icon: tableIcons.Delete,
            tooltip:
              locale === "en"
                ? "Delete Initiative Data"
                : "حذف بيانات المبادرة",
            onClick: (event, rowData) => handleDialogOpen(rowData),
          },
          {
            icon:
              activate === "activated"
                ? ScreenLockPortraitIcon
                : tableIcons.Activate,
            tooltip:
              activate === "activated"
                ? locale === "en"
                  ? "Deactivate Initiative"
                  : "إلغاء تفعيل المبادرة"
                : locale === "en"
                ? "Activate Initiative"
                : "تفعيل المبادرة",
            onClick:
              activate === "activated" ? () => {} : (event, rowData) => {},
          },
        ]}
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent
          style={{
            display: "flex",
            justifyContent: "center",
            textAlign: "center",
          }}
        >
          <p>
            {locale === "en"
              ? "ِAre you sure you want to delete this initiative data including the thawani code related to it?"
              : " هل أنت متأكد من أنك تريد حذف بيانات المبادرة بما فيها رمز ثواني المرتبط بها؟"}
            هل أنت متأكد من أنك تريد حذف بيانات المبادرة بما فيهم رمز ثواني
            المرتبط بها؟
          </p>
        </DialogContent>
        <DialogActions
          style={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            onClick={handleDialogClose}
            color="primary"
            style={{ backgroundColor: "#667085", width: "35%" }}
          >
            إلغاء
          </Button>
          <Button
            variant="contained"
            onClick={handleConfirm}
            color="primary"
            style={{ backgroundColor: "#2FDCC7", width: "35%" }}
          >
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
      {/* <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="رمز ثواني"
            type="text"
            fullWidth
            variant="standard"
            value={newRowData.thawaniCode}
            onChange={(e) =>
              setNewRowData({ ...newRowData, thawaniCode: e.target.value })
            }
          />
          <TextField
            margin="dense"
            label="الاسم"
            type="text"
            fullWidth
            variant="standard"
            value={newRowData.Name}
            onChange={(e) =>
              setNewRowData({ ...newRowData, Name: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleAddDialogClose} color="primary">
            إلغاء
          </Button>
          <Button onClick={handleAddRow} color="primary">
            إضافة
          </Button>
        </DialogActions>
      </Dialog> */}
      <Dialog open={addDialogOpen} onClose={handleAddDialogClose}>
        <DialogTitle
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "flex-end",
            fontSize: "24px",
            direction: "rtl",
          }}
        >
          إضافة مبادرة
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "center",
            width: "100%",
            direction: "rtl",
          }}
        >
          <TextField
            autoFocus
            label="رمز ثواني"
            type="text"
            dir="rtl" // Ensure text direction is RTL
            variant="outlined" // Use outlined variant
            value={newRowData.thawaniCode}
            sx={{
              width: "20rem",
              margin: "1rem",
              "& .MuiInputBase-input": {
                textAlign: "right", // Align text to the right inside the field
              },
            }}
            onChange={(e) =>
              setNewRowData({ ...newRowData, thawaniCode: e.target.value })
            }
          />
          <TextField
            label="الاسم"
            type="text"
            dir="rtl" // Ensure text direction is RTL
            variant="outlined" // Use outlined variant
            value={newRowData.Name}
            sx={{
              width: "20rem",
              margin: "1rem",
              "& .MuiInputBase-input": {
                textAlign: "right", // Align text to the right inside the field
              },
            }}
            onChange={(e) =>
              setNewRowData({ ...newRowData, Name: e.target.value })
            }
          />
        </DialogContent>
        <DialogActions
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            variant="contained"
            onClick={handleAddDialogClose}
            color="primary"
            sx={{
              bgcolor: "#667085",
              width: "35%",
              ":hover": { bgcolor: "#352D66" },
            }}
          >
            إلغاء
          </Button>
          <Button
            variant="contained"
            onClick={handleAddRow}
            color="primary"
            sx={{
              bgcolor: "#2FDCC7",
              width: "35%",
              ":hover": { bgcolor: "#352D66" },
            }}
          >
            إضافة
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
};

export default CustomEditComponent;
