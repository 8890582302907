import React from "react";
import { Routes, Route } from "react-router-dom";
import Login from "./pages/login/Login";
import Home from "./pages/home/Home";
import { LocalizationProvider } from "./contexts/LocalizationContext";

function App() {
  return (
    <LocalizationProvider>
      <div className="App">
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home/*" element={<Home />} />
        </Routes>
      </div>
    </LocalizationProvider>
  );
}

export default App;
