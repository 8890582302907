// SimpleDragDrop.js
import React, { useState, useEffect } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DragHandleIcon from "@mui/icons-material/DragHandle";
import img1 from "../images/loginClipart.png";
import img2 from "../images/img2.jpeg";
import img3 from "../images/opening.gif";
import img6 from "../images/logo2.gif";

// Initial items
const initialItems = [
  {
    id: "1",
    name: "حقيبتي المدرسية",
    image: img1,
    duration: "03:45",
  },
  {
    id: "2",
    name: "أضحية العيد",
    image: img2,
    duration: "02:30",
  },
  {
    id: "3",
    name: "سقيا الماء",
    image: img3,
    duration: "01:15",
  },
  {
    id: "4",
    name: "كهاتين",
    image: img6,
    duration: "04:20",
  },
];

const SimpleDragDrop = ({
  selectedPhoto,
  setSelectedPhoto,
  handleFileChange,
  setSelectedPhotoUrl,
}) => {
  const [items, setItems] = useState(initialItems);

  // Handle drag end
  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedItems = Array.from(items);
    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    setItems(reorderedItems);
  };
  // Convert image URL to File
  const urlToFile = async (url, filename, mimeType) => {
    const response = await fetch(url);
    const blob = await response.blob();
    return new File([blob], filename, { type: mimeType });
  };

  // Handle click on draggable item
  const handleItemClick = async (item) => {
    const file = await urlToFile(item.image, item.name, item.duration);
    setSelectedPhoto(file);
    setSelectedPhotoUrl(item.image);
  };

  useEffect(() => {
    console.log("items", items);
  }, [items]);
  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable" key={"1"}>
        {(provided) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              padding: 20,
              width: "100%",
              borderRadius: "12px 12px 0 0 ",
              background: "#f0f0f0",
              height: "100%",
              overflow: "scroll",
            }}
          >
            {items.map((item, index) => (
              <Draggable key={item.id} draggableId={item.id} index={index}>
                {(provided) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      userSelect: "none",
                      padding: 16,
                      margin: "0 0 8px 0",
                      minHeight: "50px",
                      backgroundColor: "#fff",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      color: "#333",
                      ...provided.draggableProps.style,
                    }}
                    onClick={() => handleItemClick(item)}
                  >
                    <DragHandleIcon />
                    <p style={{ marginLeft: "0" }}> {item.name}</p>
                    <img
                      src={item.image}
                      alt={item.name}
                      style={{
                        width: "100px",
                        height: "100px",
                        marginRight: 16,
                      }}
                    />
                    {item.duration}
                  </div>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default SimpleDragDrop;
