import React from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import MuiAppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import CssBaseline from "@mui/material/CssBaseline";
import List from "@mui/material/List";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import DevicesOutlinedIcon from "@mui/icons-material/DevicesOutlined";
import CampaignOutlinedIcon from "@mui/icons-material/CampaignOutlined";
import ListOutlinedIcon from "@mui/icons-material/ListOutlined";
import VideocamOutlinedIcon from "@mui/icons-material/VideocamOutlined";
import AccountBoxOutlinedIcon from "@mui/icons-material/AccountBoxOutlined";
import GroupOutlinedIcon from "@mui/icons-material/GroupOutlined";
import CreditScoreOutlinedIcon from "@mui/icons-material/CreditScoreOutlined";
import Dashboard from "../dashboard/Dashboard";
import Devices from "../devices/Devices";
import Initiatives from "../initiatives/Initiatives";
import SingleDevice from "../devices/SingleDevice";
import { Routes, Route, useNavigate, Navigate, Outlet } from "react-router-dom";
import Ads from "../ads/Ads";
import Video from "../video/Video";
import ButtonGroup from "@mui/material/ButtonGroup";
import Button from "@mui/material/Button";
import { useLocalization } from "../../contexts/LocalizationContext";
import Diversity3Icon from "@mui/icons-material/Diversity3";
import Charities from "../admin/Charities";
import CharityDetails from "../admin/CharityDetails";
import Subscribtions from "../admin/Subscribtions";
import Users from "../admin/Users";
import AdminDevices from "../admin/AdminDevices";
import AdminSingleDevice from "../admin/AdminSingleDevice";
import CharityUsers from "../charities/CharityUsers";
import Profile from "../charities/Profile";
import NotificationsIcon from "@mui/icons-material/Notifications";
import Badge from "@mui/material/Badge";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
    marginRight: drawerWidth,
  }),
}));

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  justifyContent: "flex-start",
}));

const activeStyle = {
  backgroundColor: "#f0f0f0", // Adjust as needed
  borderRadius: "4px",
};

export default function Home() {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [activeMenu, setActiveMenu] = React.useState("dashboard"); // Default active menu
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleMenuClick = (link) => {
    setActiveMenu(link);
    navigate(link);
  };

  const { locale, setLanguage } = useLocalization();
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleChangeLanguage = (lang) => {
    setLanguage(lang); // Set the language
    console.log("the setting lang is:", lang);
  };
  const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
    ({ theme, open }) => ({
      direction: locale === "en" ? "rtl" : "ltr",
      flexGrow: 1,
      marginRight: -drawerWidth,
      ...(open && {
        transition: theme.transitions.create("margin", {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen,
        }),
        marginRight: 0,
      }),
      position: "relative",
      overflow: "hidden",
    })
  );
  const menuItems = [
    {
      text: locale === "en" ? "Home" : "الصفحة الرئيسية",
      icon: <HomeOutlinedIcon />,
      link: "dashboard",
    },
    {
      text: locale === "en" ? "Devices" : "الأجهزة",
      icon: <DevicesOutlinedIcon />,
      link: "devices",
    },
    {
      text: locale === "en" ? "Advertisements" : "الإعلانات",
      icon: <CampaignOutlinedIcon />,
      link: "ads",
    },
    {
      text: locale === "en" ? "Initiatives" : "المبادرات الخيرية",
      icon: <ListOutlinedIcon />,
      link: "initiatives",
    },
    {
      text: locale === "en" ? "Thank-You Video" : "فيديو الشكر",
      icon: <VideocamOutlinedIcon />,
      link: "thank-you",
    },
  ];
  const setCharityMenu = [
    {
      text: locale === "en" ? "Users" : "المستخدمين",
      icon: <GroupOutlinedIcon />,
      link: "charityUsers",
    },
    {
      text:
        locale === "en"
          ? "Profile & Subscriptions"
          : "الملف الشخصي والاشتراكات",
      icon: <AccountBoxOutlinedIcon />,
      link: "profile",
    },
  ];
  // const setAdminMenu = [
  //   {
  //     text: locale === "en" ? "Devices" : "الأجهزة",
  //     icon: <DevicesOutlinedIcon />,
  //     link: "adminDevices",
  //   },
  //   {
  //     text: locale === "en" ? "Charities" : "اللجان",
  //     icon: <Diversity3Icon />,
  //     link: "charities",
  //   },
  //   {
  //     text: locale === "en" ? "Subscription" : "الباقات والاشتراك",
  //     icon: <CreditScoreOutlinedIcon />,
  //     link: "packages",
  //   },
  //   {
  //     text: locale === "en" ? "Users" : "المستخدمين",
  //     icon: <GroupOutlinedIcon />,
  //     link: "users",
  //   },
  // ];
  return (
    <Box sx={{ display: "flex", height: "100vh", width: "100vw" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        sx={{
          backgroundColor: "#352D66",
        }}
      >
        <Toolbar>
          <ButtonGroup
            disableElevation
            variant="contained"
            aria-label="Disabled button group"
            sx={{
              "& .MuiButtonGroup-grouped": {
                borderColor: "#ffffff", // White border between buttons
              },
              "& .MuiButtonGroup-grouped:not(:last-of-type)": {
                borderRight: `1px solid #ffffff`, // White line between buttons
              },
            }}
          >
            <Button
              value="en"
              sx={{
                backgroundColor: locale === "en" ? "#2C1E4A" : "#352D66",
                ":hover": {
                  backgroundColor: "#2C1E4A",
                },
              }}
              onClick={() => handleChangeLanguage("en")}
            >
              En
            </Button>
            <Button
              value="ar"
              sx={{
                backgroundColor: locale === "ar" ? "#2C1E4A" : "#352D66",
                ":hover": {
                  backgroundColor: "#2C1E4A",
                },
              }}
              onClick={() => handleChangeLanguage("ar")}
            >
              عربي
            </Button>
          </ButtonGroup>
          {/* <IconButton
            size="large"
            aria-label="show 17 new notifications"
            color="inherit"
            onClick={handleMenu}
          >
            <Badge badgeContent={17} color="error">
              <NotificationsIcon />
            </Badge>
          </IconButton> */}
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "center",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top", // Change to "top" to align the menu from the top with respect to the anchor element
              horizontal: "right",
            }}
            open={Boolean(anchorEl)}
            onClose={handleClose}
            PopperProps={{
              modifiers: [
                {
                  name: "offset",
                  options: {
                    offset: [10, 20], // Adjust this value to move the menu further down or horizontally
                  },
                },
              ],
            }}
          >
            <MenuItem onClick={handleClose}>
              {locale === "en"
                ? "Al Buraimy Charity Requested an upgrade"
                : "لجنة البريمي تريد ترقية الحساب"}
            </MenuItem>
            <MenuItem onClick={handleClose}>
              {locale === "en"
                ? "Al Seeb Charity Requested an upgrade"
                : "لجنة السيب تريد ترقية الحساب"}
            </MenuItem>
          </Menu>

          <Typography
            variant="h6"
            noWrap
            sx={{ flexGrow: 1 }}
            component="div"
          ></Typography>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="end"
            onClick={handleDrawerOpen}
            sx={{ ...(open && { display: "none" }) }}
          >
            <MenuIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      <Main open={open}>
        <DrawerHeader />
        {/* <marquee style={{ height: "2rem", color: "#FF0000" }} onStop={true}>
          الأجهزة المتوفرة ٢٠/١٢ جهاز ( تاريخ الانتهاء ١٢/٧/٢٠٢٣)
        </marquee> */}

        <Routes>
          <Route path="/" element={<Navigate to="dashboard" />} />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="devices" element={<Devices />} />
          <Route path="devices/:ID" element={<SingleDevice />} />
          <Route path="ads" element={<Ads />} />
          <Route path="initiatives" element={<Initiatives />} />
          <Route path="thank-you" element={<Video />} />
          <Route path="charities" element={<Charities />} />
          <Route path="charities/:ID" element={<CharityDetails />} />
          <Route path="packages" element={<Subscribtions />} />
          <Route path="users" element={<Users />} />
          <Route path="charityUsers" element={<CharityUsers />} />
          <Route path="adminDevices" element={<AdminDevices />} />
          <Route path="adminDevices/:ID" element={<AdminSingleDevice />} />
          <Route path="profile" element={<Profile />} />
        </Routes>
        <Outlet />
      </Main>
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
          },
        }}
        variant="persistent"
        anchor="right"
        open={open}
      >
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? (
              <ChevronLeftIcon />
            ) : (
              <ChevronRightIcon />
            )}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
          {menuItems.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => handleMenuClick(item.link)}
                sx={activeMenu === item.link ? activeStyle : {}}
              >
                {locale === "ar" ? (
                  <>
                    {" "}
                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  </>
                ) : (
                  <>
                    <ListItemIcon>{item.icon}</ListItemIcon>

                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                  </>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        <Divider />
        <List>
          {setCharityMenu.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => handleMenuClick(item.link)}
                sx={activeMenu === item.link ? activeStyle : {}}
              >
                {locale === "ar" ? (
                  <>
                    {" "}
                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  </>
                ) : (
                  <>
                    <ListItemIcon>{item.icon}</ListItemIcon>

                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                  </>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List>
        {/* <Divider />
        <List>
          {setAdminMenu.map((item) => (
            <ListItem key={item.text} disablePadding>
              <ListItemButton
                onClick={() => handleMenuClick(item.link)}
                sx={activeMenu === item.link ? activeStyle : {}}
              >
                {locale === "ar" ? (
                  <>
                    {" "}
                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                    <ListItemIcon>{item.icon}</ListItemIcon>
                  </>
                ) : (
                  <>
                    <ListItemIcon>{item.icon}</ListItemIcon>

                    <ListItemText
                      primary={item.text}
                      sx={{
                        textAlign: locale === "ar" ? "right" : "left",
                        marginRight: "1rem",
                      }}
                    />
                  </>
                )}
              </ListItemButton>
            </ListItem>
          ))}
        </List> */}
      </Drawer>
    </Box>
  );
}
