import React, { useState } from "react";
import MaterialTable from "material-table";
import { ThemeProvider, createTheme, styled } from "@mui/material/styles";
import {
  Typography,
  Breadcrumbs,
  Link,
  IconButton,
  Dialog,
  DialogActions,
  DialogContent,
  Button,
} from "@mui/material";
import Counter from "./Counter";
import { useLocation } from "react-router-dom";
import { MTableToolbar } from "material-table";
import { useLocalization } from "../contexts/LocalizationContext";
import { tableIcons } from "../tableIcons";
const initiativesLookup = ["كن عونا", "كسوة"];
const initiativesOptions = initiativesLookup;
const CustomIconButton = styled(IconButton)(({ theme }) => ({
  color: "#2FDCC7",
}));

function ConditionalActions() {
  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();
  const deviceName = query.get("name") || "Device";
  const { locale } = useLocalization();
  const [columns, setColumns] = useState([
    {
      field: "NumberOfDonors",
      title: locale === "en" ? "Donors" : "المتبرعين",
      render: (rowData) => (
        <Counter
          target={rowData.NumberOfDonors}
          duration={5000}
          variant={"subtitle"}
        />
      ),
      editable: "never",
    },
    {
      field: "TotalDonations",
      title: locale === "en" ? "Donations" : "إجمالي التبرعات",
      render: (rowData) => (
        <Counter
          target={rowData.TotalDonations}
          duration={5000}
          variant={"subtitle"}
        />
      ),
      editable: "never",
    },
    {
      field: "ResetDate",
      title: locale === "en" ? "Reset Date" : "تاريخ التصفير",
      editable: "never",
    },
    {
      field: "InitiativestartDate",
      title: locale === "en" ? "Start Date" : "تاريخ بداية الحملة",
      editable: "never",
    },
    {
      field: "charity",
      title: locale === "en" ? "Charity" : "اللجنة",
      editable: "never",
    },
    {
      field: "Location",
      title: locale === "en" ? "Lacation" : "الموقع",
    },
    {
      field: "ID",
      title: locale === "en" ? "Reset Number" : "رقم التصفير",
      editable: "never",
    },
  ]);

  const data = [
    {
      ID: "1",
      Location: "مسقط",
      charity: " لجنة النور",
      InitiativestartDate: "2024-07-01",
      ResetDate: "2024-07-10",
      TotalDonations: 1000,
      NumberOfDonors: 10,
    },
    {
      ID: "1",
      Location: "مسقط",
      charity: " لجنة النور",
      InitiativestartDate: "2024-07-01",
      ResetDate: "2024-07-10",
      TotalDonations: 1000,
      NumberOfDonors: 10,
    },
    {
      ID: "1",
      Location: "مسقط",
      charity: " لجنة النور",
      InitiativestartDate: "2024-07-01",
      ResetDate: "2024-07-10",
      TotalDonations: 1000,
      NumberOfDonors: 10,
    },
    {
      ID: "2",
      Location: "صلالة",
      charity: "لجنة زكاة السيب",
      InitiativestartDate: "2024-07-05",
      ResetDate: "2024-07-15",
      TotalDonations: 500,
      NumberOfDonors: 5,
    },
  ];

  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const handleDialogOpen = (rowData) => {
    setSelectedRow(rowData);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setSelectedRow(null);
    setDialogOpen(false);
  };

  const handleConfirm = () => {
    console.log("Confirmed for:", selectedRow);
    handleDialogClose();
  };

  const defaultMaterialTheme = createTheme({
    components: {
      MuiIconButton: {
        styleOverrides: {
          root: {
            "&:not(.export-button)": {
              color: "#333333",
            },
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            fontWeight: "bold",
            fontSize: "20px",
            color: "#333333",
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={defaultMaterialTheme}>
      <MaterialTable
        title=""
        columns={columns}
        icons={tableIcons}
        data={data}
        options={{
          search: false,
          paging: false,
          exportButton: true,
          headerStyle: {
            textAlign: locale === "en" ? "left" : "right",
          },
          cellStyle: {
            textAlign: "center",
          },
        }}
        components={{
          Toolbar: (props) => (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                width: "98%",
              }}
            >
              <MTableToolbar {...props} />
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-end",
                  marginTop: "2rem",
                  alignItems: locale === "en" ? "flex-end" : "flex-start",
                  direction: "rtl",
                }}
              >
                <Breadcrumbs
                  aria-label="breadcrumb"
                  sx={{
                    "& .MuiBreadcrumbs-separator": {
                      content: "'<'",
                    },
                  }}
                >
                  <Link
                    color="inherit"
                    href="/home/adminDevices"
                    sx={{
                      textDecoration: "none",
                      fontWeight: "bold",
                    }}
                  >
                    جميع الأجهزة
                  </Link>
                  <Typography
                    color="textPrimary"
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {deviceName}
                  </Typography>
                </Breadcrumbs>
                <Typography variant="h3">{deviceName}</Typography>
              </div>
            </div>
          ),
        }}
        localization={{
          header: {
            actions: "الإجراءات",
          },
          body: {
            editTooltip: "تعديل",
            editRow: {
              deleteText: "هل أنت متأكد أنك تريد حذف هذا الصف؟",
              cancelTooltip: "إلغاء",
              saveTooltip: "حفظ",
            },
          },
        }}
      />
      <Dialog open={dialogOpen} onClose={handleDialogClose}>
        <DialogContent>
          <Typography>
            {selectedRow
              ? `هل أنت متأكد أنك تريد تصفير بيانات الحساب للجهاز ${selectedRow.ID}؟`
              : ""}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            إلغاء
          </Button>
          <Button onClick={handleConfirm} color="primary">
            تأكيد
          </Button>
        </DialogActions>
      </Dialog>
    </ThemeProvider>
  );
}

export default ConditionalActions;
